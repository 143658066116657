<template>
  <div>
    <button @click="addVideo" type="button" class="rem-btn upload-video">
      Upload Video
    </button>
    <!-- <button
      id="start"
      @click="startRecordVideo"
      type="button"
      class="rem-btn upload-video start-record"
    >
      Start Recording
    </button>
    <button
      id="stop"
      @click="stopvideo"
      v-if="btnstop"
      :disabled="btnLoader"
      type="submit"
      class="btn btn-danger"
    >
      <span v-if="btnLoader"
        ><span class="spinner-grow spinner-grow-sm"></span> Loading...</span
      >
      <span v-if="!btnLoader"> Stop Recording</span>
    </button> -->
    <!-- <video ref="vi" :src="this.src" autoplay controls></video>-->

    <br /><br />
    <div class="leads-table-area dealers-table">
      <table class="display" id="dealers_table">
        <thead>
          <tr>
            <th>Video Title</th>
            <th>Video Category</th>
            <th>Video Description</th>
            <th>Video link</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- upload  video -->
    <div
      class="modal fade"
      id="uploadvideo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :class="editMode ? 'editMode' : ''"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload Video</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="add-member add-lead">
                  <form
                    @submit.prevent="
                      editMode ? updateVideoDetails() : uploadVideo()
                    "
                    enctype="multipart/form-data"
                  >
                    <div class="form-group">
                      <label> Video Title</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="video.title"
                      />
                    </div>
                    <div class="form-group">
                      <label> Video Category</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="video.category"
                      />
                    </div>
                    <div class="form-group">
                      <label> Video Description</label>
                      <textarea
                        class="form-control"
                        v-model="video.description"
                      ></textarea>
                    </div>
                    <div v-if="!editMode" class="form-group">
                      <label> Video</label>
                      <input
                        type="file"
                        class="form-control"
                        v-on:change="onFileChange"
                      />
                    </div>
                    <div v-if="editMode">
                      <video
                        width="100%"
                        controls
                        autoplay
                        :src="video.link"
                      ></video>
                    </div>

                    <button
                      v-if="!editMode"
                      class="rem-btn add-video"
                      type="submit"
                    >
                      <span v-if="btnstop">
                        <span class="spinner-grow spinner-grow-sm"></span>
                        Loading...
                      </span>
                      <span v-else
                        ><img
                          src="/img/23. Close.f3f049ce.svg"
                          alt=""
                          class="img-fluid"
                        />
                        Add</span
                      >
                    </button>
                    <button
                      v-if="editMode"
                      class="rem-btn add-video"
                      type="submit"
                    >
                      <span
                        ><img
                          src="/img/23. Close.f3f049ce.svg"
                          alt=""
                          class="img-fluid"
                        />
                        Update</span
                      >
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- upload  video -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

export default {
  data() {
    return {
      dealer_id: "",
      crm: "",
      thirdpartycrm: [],
      file: "",
      allDealres: [],
      btnLoader: false,
      dealer: {},
      video: {
        title: "",
        category: "",
        description: "",
        link: "",
        id: "",
      },
      recorder: "",
      stream: "",
      mimeType: "video/webm",
      src: "",
      btnstop: false,
      editMode: false,
      dataTableObject: null,
    };
  },
  created() {
    this.$root.$on("action", function (action) {
      if (action == "refresvideos") {
        $("#dealers_table").dataTable().fnDraw();
      }
    });
  },
  beforeUpdate() {
    if (this.dataTableObject) {
      this.dataTableObject.fnDestroy();
    }
  },
  afterUpdate() {
    if (this.dataTableObject) {
      this.dataTableObject.fnDestroy();
      this.dataTableObject.fnDraw();
    }
  },
  mounted() {
    let thisObject = this;
    this.loadVideosData();

    $(document).on("click", ".edit-vid", function () {
      var id = $(this).data("id");
      thisObject.editMode = true;
      $("#uploadvideo").modal("show");
      axios
        .get("/getvideodetails/" + id, {
          headers: {
            Authorization: "Bearer " + v.$storage.get("auth").token,
          },
        })
        .then(function (response) {
          console.log(response.data.video_details);
          thisObject.video.title = response.data.video_details.video_title;
          thisObject.video.category =
            response.data.video_details.video_category;
          thisObject.video.description =
            response.data.video_details.video_description;
          thisObject.video.link = response.data.video_details.video_link;
          thisObject.video.id = response.data.video_details.id;
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    $(document).on("click", ".copy", function () {
      var id = $(this).data("id");
      let testingCodeToCopy = document.querySelector("#testing-code" + id + "");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log(msg);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    });

    let v = this;
    $(document).on("click", ".delete-vid", function () {
      var id = $(this).data("id");
      v.$confirm({
        title: `Are you sure?`,
        message: "you want to delete this video?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            //console.log(member)
            axios
              .delete(`delete_video/${id}`, {
                headers: {
                  Authorization: "Bearer " + v.$storage.get("auth").token,
                },
              })
              .then((res) => {
                if (res.data.status) {
                  $("#dealers_table").dataTable().fnDraw();
                }
                if (res.data.status == false) {
                  alert(res.data.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    });
  },
  methods: {
    startRecordVideo() {
      this.startRecording();
    },
    stopvideo() {
      this.recorder.stop();
      this.stream.getVideoTracks()[0].stop();
      this.stream.getAudioTracks()[0].stop();
      console.log("test");
    },
    async startRecording() {
      let videoStramConstraints = {
        audio: { echoCancellation: true },
        video: { mediaSource: "screen" },
      };
      const displayStream = await navigator.mediaDevices.getDisplayMedia(
        videoStramConstraints
      );

      let audioStreamConstraints = {
        audio: { echoCancellation: true },
        video: false,
      };
      const voiceStream = await navigator.mediaDevices.getUserMedia(
        audioStreamConstraints
      );
      let tracks = [
        ...displayStream.getTracks(),
        ...voiceStream.getAudioTracks(),
      ];

      this.stream = new MediaStream(tracks);

      this.recorder = new MediaRecorder(this.stream);

      const chunks = [];
      this.recorder.ondataavailable = (e) => chunks.push(e.data);
      this.btnstop = true;
      this.recorder.onstop = (e) => {
        console.log(e);
        this.btnLoader = true;
        const completeBlob = new Blob(chunks, { type: this.mimeType });
        this.src = URL.createObjectURL(completeBlob);

        // Upload Functionality Starts
        let uploadFormData = new FormData();
        uploadFormData.append("recordedvideo", completeBlob);
        uploadFormData.append("mp4_title", "Test File Upload");

        axios
          .post("/uploadmp4video", uploadFormData, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((response) => {
            if (response.data.status) {
              $("#dealers_table").dataTable().fnDraw();
              this.btnLoader = false;
              alert("Recorded video Uploaded");
            } else {
              this.btnLoader = false;
              alert(response.data.status);
            }
          })
          .catch(function (error) {
            this.btnLoader = false;
            alert(error);
          });
        // Upload functionality Ends
        console.log(e);
      };

      this.recorder.start();
    },
    onCopy: function (e) {
      alert("You just copied the following text to the clipboard: " + e.text);
    },
    onError: function (e) {
      alert("Failed to copy the text to the clipboard");
      console.log(e);
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
    },
    uploadVideo() {
      let thisObject = this;
      thisObject.btnstop = true;
      const config = {
        headers: {
          Authorization: "Bearer " + this.$storage.get("auth").token,
        },
      };
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("video_title", this.video.title);
      formData.append("video_category", this.video.category);
      formData.append("video_description", this.video.description);
      axios
        .post("/uploadvideo", formData, config)
        .then(function (response) {
          console.log(response);
          if (response.data.status) {
            $("#dealers_table").dataTable().fnDraw();
            thisObject.btnstop = false;
            alert(response.data.message);
            $("#uploadvideo").modal("hide");
          } else {
            alert("Something Wrong");
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
    updateVideo(id) {
      console.log("update function");
      console.log(id);
    },
    updateVideoDetails() {
      const config = {
        headers: {
          Authorization: "Bearer " + this.$storage.get("auth").token,
        },
      };
      let thisObject = this;
      axios
        .post("/updatevideodetails", this.video, config)
        .then(function (response) {
          console.log(response);
          if (response.data.status) {
            $("#uploadvideo").modal("hide");
            console.log(thisObject);
            thisObject.editMode = false;
            thisObject.loadVideosData();
          } else {
            alert("Something Wrong");
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
    addVideo() {
      this.editMode = false;
      this.video.title = "";
      this.video.category = "";
      this.video.description = "";
      this.video.link = "";
      this.video.id = "";
      $("#uploadvideo").modal("show");
    },
    loadVideosData() {
      this.dataTableObject = $("#dealers_table")
        .addClass("nowrap")
        .dataTable({
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          order: [[2, "desc"]],
          ajax: {
            url: axios.defaults.baseURL + "get/videos",
            type: "POST",
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          },
          columns: [
            {
              data: "video_title",
            },
            {
              data: "video_category",
              orderable:false
            },
            {
              data: "video_description",
            },
            {
              data: "video_link",
            },
            {
              data: "created_at",
            },
            {
              data: "action",
            },
          ],
        })
        .fnDraw();
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
.add-video {
  float: right;
  margin-bottom: 20px;
}
.upload-video {
  float: right;
  margin-bottom: 30px;
}
.start-record {
  margin-right: 7px;
  float: right;
}
</style>